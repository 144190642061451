// ** Redux Imports
import { combineReducers } from 'redux';

// ** Reducers Imports
import categories from './categories';
import elements from './element';

const rootReducer = combineReducers({
  categories,
  elements
});

export default rootReducer;
