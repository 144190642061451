import {
  CREATE_Element_SUCCESS,
  CREATE_Element_FAIL,
  DELETE_Element_SUCCESS,
  DELETE_Element_FAIL,
  UPDATE_Element_SUCCESS,
  UPDATE_Element_FAIL,
  GET_ElementS_SUCCESS,
  GET_ElementS_FAIL,
  GET_Element_ID
} from './type';

import { LIST_ELEMENTS_SUCCESS } from '../categories/type';
import ElementService from '../../../services/ElementService';
import CategorieService from '../../../services/CategorieService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadingToggleAction } from '../categories';

export const createElement = (data, title, startDate, endDate) => async (dispatch) => {
  try {
    const [res] = await Promise.all([
      CategorieService.listTreso('treso', startDate, endDate),
      CategorieService.listCompta('compta', startDate, endDate),
      ElementService.create(data, title, startDate, endDate)
    ]);

    dispatch({
      type: CREATE_Element_SUCCESS,
      payload: res.data.copie
    });
    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: CREATE_Element_FAIL,
      payload: message
    });

    toast.error(' vous devez terminer la saisie ');

    return Promise.resolve(error);
  }
};

export const createElementbydate = (data, title, startDate, endDate) => async (dispatch) => {
  try {
    const res = await ElementService.create(data, title, startDate, endDate);

    const idElement = res.data.id;

    dispatch({
      type: CREATE_Element_SUCCESS,
      payload: res.data.copie
    });

    dispatch({
      type: LIST_ELEMENTS_SUCCESS,
      payload: res.data.elements
    });

    /* dispatch({
      type: GET_Element_ID,
      payload: idElement
    }); */
    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: CREATE_Element_FAIL,
      payload: error
    });

    toast.error(' vous devez terminer la saisie ');
    return Promise.resolve(error);
  }
};

export const getElement = (id) => (dispatch) => {
  return ElementService.get(id).then(
    (response) => {
      dispatch({
        type: GET_ElementS_SUCCESS,
        payload: response.data
      });
      dispatch(loadingToggleAction(false));

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: GET_ElementS_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const deleteElement = (id, title, startDate, endDate) => (dispatch) => {
  return ElementService.deleteElement(id, title, startDate, endDate).then(
    (response) => {
      dispatch({
        type: DELETE_Element_SUCCESS,
        payload: response.data.data.copie
      });

      dispatch({
        type: LIST_ELEMENTS_SUCCESS,
        payload: response.data.data.elements
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: DELETE_Element_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const updateElement = (id, data, title, startDate, endDate) => (dispatch) => {
  return ElementService.update(id, data, title, startDate, endDate).then(
    (response) => {
      dispatch({
        type: UPDATE_Element_SUCCESS,
        payload: response.data.copie
      });

      dispatch({
        type: LIST_ELEMENTS_SUCCESS,
        payload: response.data.elements
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: UPDATE_Element_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};
