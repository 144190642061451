import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ajoutBudget from '../../assets/icon/ajoutBudget.svg';
import { colorSoldeTotal, editingAction, listSolde } from '../../redux/actions/categories';
import prev from '../../assets/icon/prev.svg';
import engage from '../../assets/icon/engage.svg';
import pointe from '../../assets/icon/pointe.svg';
import simulation from '../../assets/icon/simulation.svg';
import reel from '../../assets/icon/reel.svg';
import filter from '../../assets/icon/filtreSolde.svg';
import close from '../../assets/icon/Close.svg';

import './Thead.css';
import { filterSoldeByColor } from '../../helpers/filterSoldeByStatus';
function Thead({ className, monthsTotalData, dataSource, setDataSource, title, ...props }) {
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(false);
  const [modeActive, setModeActive] = React.useState(false);
  const colorSolde = useSelector((state) => state.categories.colorSolde);
  const dateFilter = useSelector((state) => state.categories.dateFilter);

  // Showing every month's total to the user
  const getMonthTotal = React.useCallback(
    (month) => (
      <div className="month-total">
        {monthsTotalData[month]?.toLocaleString('fi-FI', {
          style: 'currency',
          currency: 'EUR'
        })}
      </div>
    ),
    [monthsTotalData]
  );

  React.useEffect(() => {
    if (location.pathname == '/') {
      setModeActive(modeActive);
    } else if (location.pathname == '/table-compta') {
      setModeActive(!modeActive);
    }
  }, []);

  const handleAdd = () => {
    const newData = {
      name: ''
    };
    setDataSource([...dataSource, newData]);
    dispatch(editingAction(true));
  };

  const handleClickActive = () => {
    setActive(!active);
  };
  const handleFilterSolde = async (filterSolde) => {
    const color = filterSoldeByColor(filterSolde);
    dispatch(colorSoldeTotal(color));

    if (location.pathname == '/') {
      await dispatch(listSolde('treso', filterSolde, dateFilter.startDate, dateFilter.endDate));

      // await dispatch(listSolde('treso', filterSolde));
    } else if (location.pathname == '/table-compta') {
      await dispatch(listSolde('compta', filterSolde, dateFilter.startDate, dateFilter.endDate));

      // await dispatch(listSolde('compta', filterSolde));
    }
  };

  return (
    <thead className={className}>
      {props.children}
      <tr>
        <th className="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last solde">
          <div className="filterSolde">
            <span>Solde total</span>
            <div className={active ? 'header__right__filterActive' : null} onClick={handleClickActive}>
              <img src={filter} />
              {active && !modeActive && (
                <div className="header__right__filterActive__modeFilterActiveTreso">
                  <img src={prev} onClick={() => handleFilterSolde(1)} />
                  <img src={engage} onClick={() => handleFilterSolde(2)} />
                  <img src={pointe} onClick={() => handleFilterSolde(3)} />
                  <img src={close} onClick={() => handleFilterSolde()} />
                </div>
              )}
              {active && modeActive && (
                <div className="header__right__filterActive__modeFilterActiveCompta">
                  <img src={simulation} onClick={() => handleFilterSolde('Simulation')} />
                  <img src={reel} onClick={() => handleFilterSolde('Relle')} />
                  <img src={close} onClick={() => handleFilterSolde()} />
                </div>
              )}
            </div>
          </div>
        </th>
        {dateFilter?.monthArray.map((month) => {
          return (
            <>
              <th style={{ backgroundColor: colorSolde }}>{getMonthTotal(`${'solde' + month}`)}</th>
            </>
          );
        })}
        <th>{getMonthTotal('')}</th>
      </tr>
      <tr>
        <th className="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last cattegory">
          <div className="namecolumn">
            <span>Gestion de catégories</span>
            <div>
              <img src={ajoutBudget} className="ajoutBudget" onClick={() => handleAdd()} />
            </div>
          </div>
        </th>
        {dateFilter?.monthArray.map((month) => {
          return (
            <>
              <th className="ant-table-cell category-thead-container">
                <CategoryThead />
              </th>
            </>
          );
        })}
        <th className="ant-table-cell category-thead-container"></th>
      </tr>
    </thead>
  );
}

function CategoryThead() {
  return (
    <div className="category-thead">
      <p>Libellé</p>
      <p>Montant</p>
    </div>
  );
}

export default Thead;
