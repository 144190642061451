export const CREATE_Element_SUCCESS = 'CREATE_Element_SUCCESS';
export const CREATE_Element_FAIL = 'CREATE_Element_FAIL';

export const LIST_Element_SUCCESS = 'LIST_Element_SUCCESS';
export const LIST_Element_FAIL = 'LIST_Element_FAIL';

export const DELETE_Element_SUCCESS = 'DELETE_Element_SUCCESS';
export const DELETE_Element_FAIL = 'DELETE_Element_FAIL';

export const GET_ElementS_SUCCESS = 'GET_ElementS_SUCCESS';
export const GET_ElementS_FAIL = 'GET_ElementS_FAIL';
export const GET_Element_ID = 'GET_Element_ID';

export const UPDATE_Element_SUCCESS = 'UPDATE_Element_SUCCESS';
export const UPDATE_Element_FAIL = 'UPDATE_Element_FAIL';
