import React from 'react';
// import css
import './Sidebar.scss';
import { Tooltip } from 'antd';

// Import icon
import rectangleSideBar from '../../assets/icon/RectangleSideBar.svg';

// import side bar data
import { SidebarData } from '../../data/SidebarData';

import { NavLink } from 'react-router-dom';

const SideBar = () => {
  return (
    <React.Fragment>
      <div className="sideBarContainer">
        <div className="sideBarContainer__logos">
          {SidebarData.map((menuItem, index) => (
            <Tooltip placement="right" key={index} title={menuItem.name}>
              <NavLink to={menuItem.path} className={menuItem.activeClass ? 'iconSideBarActive' : 'iconSideBar'}>
                <div className="sideBarContainer__logos__active">
                  <div>
                    <img src={menuItem.icon} />
                  </div>
                  <div>{menuItem.isActive && <img src={rectangleSideBar} />}</div>
                </div>
              </NavLink>
            </Tooltip>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SideBar;
