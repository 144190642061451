import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { chekDate } from '../../helpers/checkDate';
import { getQueryStringParams, MONTHS } from '../../helpers/helpers';
import { listCategorie, listSolde, updateCategorie } from '../../redux/actions/categories';
import { updateElement } from '../../redux/actions/element';

const DragAndDropContext = (props) => {
  const dateFilter = useSelector((state) => state.categories.dateFilter);

  const dispatch = useDispatch();

  const { children, apiData, setApiData, title } = props;

  const onDragEnd = async (result) => {
    // In all cases first we need to get the dragged item ( it may be category or element )
    // getQueryStringParams returns ->
    // category which is category ID
    // month which is month's name like January
    // index in case of element -> index of the element inside the elements array under category object
    const {
      category: draggedCategory,
      month: draggedMonth,
      index: draggedIndex,
      type: draggedType,
      statut: draggedStatut,
      levelId: draggedLevel
    } = getQueryStringParams(result.draggableId);
    // There are serval conditions we need to check
    // before exiting the onDragEnd method
    // 1- if the result is null and the dragged item is an element
    // 2- or result not null and the draggable item was droped in the same location
    if ((!result.destination && draggedMonth !== 'CATEGORY') || (result.destination && result.draggableId === result.destination.droppableId))
      // in case there's no destination or dragged element dropped on it's same position
      return;

    // Same for the one on top, except here we are getting
    // info about the dropped item
    // it may be a category or an element
    const { category: droppedOnCategory, month: droppedOnMonth, levelId: droppedOnLevel } = getQueryStringParams(result.destination?.droppableId || '');

    console.log('droppedOnMonth', droppedOnMonth);
    console.log('draggedMonth', draggedMonth);

    // We need to check if an element was dropped on a category or the inverse
    // If so, we need to trigger an exception ( I've used a javascript Alert )
    if (
      (draggedMonth === 'CATEGORY' && MONTHS.includes(droppedOnMonth?.split(' ')[0] || '')) ||
      (droppedOnMonth === 'CATEGORY' && MONTHS.includes(draggedMonth?.split(' ')[0] || ''))
    ) {
      alert("Problem: You're trying to drag and drop element on a category or the inverse.");
      return;

      // We made sure that everything is ready to visualize
      // the drag and drop for the user
    } else {
      // MONTHS array contains all the months
      // We just need to know if drag & drop was done
      // on elements or a category
      // This case means that the dnd was done on elements
      if (MONTHS.includes(draggedMonth?.split(' ')[0] || '') || MONTHS.includes(droppedOnMonth?.split(' ')[0] || '')) {
        // in case each category id is string otherwise use == instead of ===
        const [draggedElement] = apiData.find((c) => c.id == draggedCategory)?.elements[draggedMonth || '']?.splice([draggedIndex || 0], 1);

        const _apiData = Array.from(apiData);

        if (title === 'Trésorerie') {
          if (draggedStatut == 3) {
            toast.info(
              "Attention: cette action n'est pas applicable pour les éléments de type Pointé, déplacement vertical seulement (d'une catégorie à une autre ) "
            );
            const updateData = {
              categorieId: droppedOnCategory
            };
            await dispatch(updateElement(draggedElement?.id, updateData, title, dateFilter.startDate, dateFilter.endDate));
            await dispatch(listSolde('treso', '', dateFilter.startDate, dateFilter.endDate));
          } else {
            const updateData = {
              dateTreso: chekDate(droppedOnMonth),
              categorieId: droppedOnCategory
            };
            await dispatch(updateElement(draggedElement?.id, updateData, title, dateFilter.startDate, dateFilter.endDate));
            await dispatch(listSolde('treso', '', dateFilter.startDate, dateFilter.endDate));
          }
        } else if (title === 'Comptabilité') {
          if (draggedType === 'Relle') {
            toast.info(
              "Attention: cette action n'est pas applicable pour les éléments de type réels, déplacement vertical seulement (d'une catégorie à une autre ) "
            );
            const updateData = {
              categorieId: droppedOnCategory
            };
            await dispatch(updateElement(draggedElement?.id, updateData, title, dateFilter.startDate, dateFilter.endDate));
            await dispatch(listSolde('compta', '', dateFilter.startDate, dateFilter.endDate));
          } else {
            const updateData = {
              dateCompta: chekDate(droppedOnMonth),
              dateTreso: chekDate(droppedOnMonth),
              categorieId: droppedOnCategory
            };
            await dispatch(updateElement(draggedElement?.id, updateData, title, dateFilter.startDate, dateFilter.endDate));
            await dispatch(listSolde('compta', '', dateFilter.startDate, dateFilter.endDate));
          }
        }

        // setRefresh(!refresh);
        // _apiData.find((c) => c.id === droppedOnCategory)?.elements[droppedOnMonth || ''].push(draggedElement);

        // setApiData(_apiData);
      } else {
        // This case means that the dnd was done on categories
        const _apiData = Array.from(apiData);
        if (!result.destination) {
          // in this case if the result.destination is null
          // we need to set the parentId of the category to null
          // so it goes to the top level.
          _apiData.find((c) => c.id == draggedCategory).parentId = null;

          const dataCatgeory = {
            name: _apiData.find((c) => c.id == draggedCategory).name,
            parentId: null,
            levelId: 1
          };

          dispatch(updateCategorie(draggedCategory, dataCatgeory));
        } else {
          const draggedcategoryParent = _apiData.find((c) => c.id == draggedCategory).parentId;
          const draggedId = _apiData.find((c) => c.id == draggedCategory).id;
          const droppedOncategoryParent = _apiData.find((c) => c.id == droppedOnCategory).parentId;

          if ((draggedcategoryParent === null && droppedOncategoryParent === draggedId) || droppedOncategoryParent === draggedId) {
            toast.error("Attention: Ce n'est pas possible de placer une catégorie parente dans son fils");
          }

          // otherwise we just need to set the category parentId
          else {
            _apiData.find((c) => c.id == draggedCategory).parentId = JSON.parse(droppedOnCategory);

            const dataCatgeory = {
              name: _apiData.find((c) => c.id == draggedCategory).name,
              parentId: JSON.parse(droppedOnCategory),
              levelId: JSON.parse(droppedOnLevel) + 1
            };

            dispatch(updateCategorie(draggedCategory, dataCatgeory));
          }

          // setExpandedCategories(expandedCategories.filter((ec: any) => ec !== draggedCategory));
          // setExpandedCategories([...expandedCategories, droppedOnCategory]);
        }

        // Setting the new data so the user will get the expected result.
        // In this case you may call an api request to save the new data
        // in the database and REFETCH to get the expected result in
        // the first render.
        setApiData(_apiData);
      }
    }

    // end of onDragEnd
    return;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <tbody>{children}</tbody>
    </DragDropContext>
  );
};

export default DragAndDropContext;
