import {
  CREATE_CATEGORIE_SUCCESS,
  CREATE_CATEGORIE_FAIL,
  LIST_CATEGORIE_SUCCESS,
  LIST_CATEGORIE_FAIL,
  LIST_CATEGORIE_All,
  LIST_CATEGORIE_SUCCESS_FINAL,
  LIST_ELEMENTS_SUCCESS,
  GET_CATEGORIE_SUCCESS,
  GET_CATEGORIE_FAIL,
  UPDATE_CATEGORIE_SUCCESS,
  EDITING_ACTION,
  LOADING_TOGGLE_ACTION,
  COLOR_SOLDE_ACTION,
  DELETE_CATEGORIE_SUCCESS,
  FILTER_DATE_ACTION
} from '../../actions/categories/type';

import { UPDATE_Element_SUCCESS, CREATE_Element_SUCCESS, DELETE_Element_SUCCESS } from '../../actions/element/type';
const initialState = {
  categories: [],
  elements: [],
  categorie: null,
  showLoading: false,
  editing: false,
  colorSolde: null,
  dateFilter: null
};
const categorieReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case LIST_CATEGORIE_All:
      return {
        ...state,
        categoriesAll: payload
      };
    case LIST_ELEMENTS_SUCCESS:
      return {
        ...state,
        elements: payload
      };
    case LIST_CATEGORIE_SUCCESS_FINAL:
      return {
        ...state,
        soldeAll: payload
      };
    case LIST_CATEGORIE_FAIL:
      return {
        ...state
      };
    case CREATE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, payload]
      };

    case CREATE_CATEGORIE_FAIL:
      return {
        ...state,
        createFail: payload
      };

    case GET_CATEGORIE_SUCCESS:
      return {
        ...state,
        categorie: payload
      };
    case GET_CATEGORIE_FAIL:
      return {
        ...state,
        categorie: payload
      };
    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: payload
      };
    case UPDATE_Element_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case DELETE_Element_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case CREATE_Element_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case UPDATE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case EDITING_ACTION:
      return {
        ...state,
        editing: payload
      };

    case COLOR_SOLDE_ACTION:
      return {
        ...state,
        colorSolde: payload
      };
    case DELETE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case FILTER_DATE_ACTION:
      return {
        ...state,
        dateFilter: payload
      };
  }
  return <div>index</div>;
};

export default categorieReducer;
