import {
  CREATE_CATEGORIE_SUCCESS,
  CREATE_CATEGORIE_FAIL,
  LIST_CATEGORIE_SUCCESS,
  LIST_CATEGORIE_FAIL,
  LIST_CATEGORIE_All,
  LIST_CATEGORIE_SUCCESS_FINAL,
  UPDATE_CATEGORIE_SUCCESS,
  UPDATE_CATEGORIE_FAIL,
  DELETE_CATEGORIE_SUCCESS,
  DELETE_CATEGORIE_FAIL,
  LIST_ELEMENTS_SUCCESS,
  GET_CATEGORIE_SUCCESS,
  GET_CATEGORIE_FAIL,
  LOADING_TOGGLE_ACTION,
  EDITING_ACTION,
  COLOR_SOLDE_ACTION,
  FILTER_DATE_ACTION
} from './type';

import CategorieService from '../../../services/CategorieService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const createCategorie = (data) => async (dispatch) => {
  try {
    const res = await CategorieService.create(data);

    dispatch({
      type: CREATE_CATEGORIE_SUCCESS,
      payload: res.data
    });
    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch(loadingToggleAction(false));

    dispatch({
      type: CREATE_CATEGORIE_FAIL,
      payload: message
    });
    toast.error('vous devez nommer la catégorie');

    return Promise.resolve(error);
  }
};

export const listCategorie = (params, startDate, endDate) => (dispatch) => {
  if (params === 'treso') {
    return CategorieService.listTreso(startDate, endDate).then(
      (response) => {
        dispatch({
          type: LIST_CATEGORIE_SUCCESS,
          payload: response.data.copie
        });

        dispatch({
          type: LIST_ELEMENTS_SUCCESS,
          payload: response.data.elements
        });

        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        dispatch({
          type: LIST_CATEGORIE_FAIL,
          payload: message
        });

        return Promise.reject();
      }
    );
  } else if (params === 'compta') {
    return CategorieService.listCompta(startDate, endDate).then(
      (response) => {
        dispatch({
          type: LIST_CATEGORIE_SUCCESS,
          payload: response.data.copie
        });

        dispatch({
          type: LIST_ELEMENTS_SUCCESS,
          payload: response.data.elements
        });

        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        dispatch({
          type: LIST_CATEGORIE_FAIL,
          payload: message
        });

        return Promise.reject();
      }
    );
  }
};

export const listSolde = (params, soldeFilter, startDate, endDate) => (dispatch) => {
  if (params === 'treso') {
    return CategorieService.listSoldeTrseo(soldeFilter, startDate, endDate).then(
      (response) => {
        dispatch({
          type: LIST_CATEGORIE_SUCCESS_FINAL,
          payload: response.data.totalSoldesMonth
        });

        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data.ArraySoldes && error.response.data.ArraySoldes.message) || error.message || error.toString();

        dispatch({
          type: LIST_CATEGORIE_FAIL,
          payload: message
        });

        return Promise.reject();
      }
    );
  } else if (params === 'compta') {
    return CategorieService.listSoldeCompta(soldeFilter, startDate, endDate).then(
      (response) => {
        dispatch({
          type: LIST_CATEGORIE_SUCCESS_FINAL,
          payload: response.data.totalSoldesMonth
        });

        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data.ArraySoldes && error.response.data.ArraySoldes.message) || error.message || error.toString();

        dispatch({
          type: LIST_CATEGORIE_FAIL,
          payload: message
        });

        return Promise.reject();
      }
    );
  }
};

export const listCategoriePopup = () => (dispatch) => {
  return CategorieService.listCategoriePopup().then(
    (response) => {
      dispatch({
        type: LIST_CATEGORIE_All,
        payload: response.data
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: LIST_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const updateCategorie = (id, data) => (dispatch) => {
  return CategorieService.update(id, data).then(
    (response) => {
      dispatch(loadingToggleAction(false));

      dispatch({
        type: UPDATE_CATEGORIE_SUCCESS,
        payload: response.data.copie
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data.copie && error.response.data.copie.message) || error.message || error.toString();

      dispatch({
        type: UPDATE_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const deleteCategorie = (id, soldeFilter, startDate, endDate) => (dispatch) => {
  return CategorieService.deleteCategorie(id, soldeFilter, startDate, endDate).then(
    (response) => {
      dispatch({
        type: DELETE_CATEGORIE_SUCCESS,
        payload: response.data.data.copie
      });

      dispatch({
        type: LIST_CATEGORIE_SUCCESS_FINAL,
        payload: response.data.totalSoldesMonth
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data.copie && error.response.data.copie.message) || error.message || error.toString();

      dispatch({
        type: DELETE_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const getCategory = (id) => (dispatch) => {
  return CategorieService.get(id).then(
    (response) => {
      dispatch({
        type: GET_CATEGORIE_SUCCESS,
        payload: response.data
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: GET_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const loadingToggleAction = (status) => (dispatch) => {
  dispatch({
    type: LOADING_TOGGLE_ACTION,
    payload: status
  });
};

export const colorSoldeTotal = (color) => (dispatch) => {
  dispatch({
    type: COLOR_SOLDE_ACTION,
    payload: color
  });
};

export const editingAction = (status, id) => (dispatch) => {
  if (id) {
    dispatch({
      type: EDITING_ACTION,
      payload: { status, id }
    });
  } else {
    dispatch({
      type: EDITING_ACTION,
      payload: status
    });
  }
};

export const filterDateAction = (startDate, endDate, monthArray) => (dispatch) => {
  if (monthArray) {
    dispatch({
      type: FILTER_DATE_ACTION,
      payload: { startDate, endDate, monthArray }
    });
  } else {
    dispatch({
      type: FILTER_DATE_ACTION,
      payload: { startDate, endDate }
    });
  }
};
