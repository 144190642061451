function getMonthData(record, month, title) {
  return {
    monthTotal: record['montantTotal' + month] || '',
    monthElements: record?.elements?.[month] || [],
    dragPrefix: 'category=' + record.id + '&month=' + month + '&levelId=' + record.levelId,
    indexMultiplier: record.id,
    categoryName: record.name,
    title: title
  };
}

function getDefaultColumns(renderCell, title, renderAction, dateFilter) {
  const year = dateFilter?.endDate?.slice(0, 4);
  const monthsArray = dateFilter?.monthArray;
  let arrayCoulnms = [
    {
      title: title,
      dataIndex: 'name',
      width: '200px',
      fixed: 'left',
      className: 'ColoumnHeaderTitle',
      render: (value, record) => renderCell(getMonthData(record, 'CATEGORY', title))
    }
  ];

  const dataFilterArray = () => {
    monthsArray?.forEach((month) => {
      let object = {
        title: `${month}`,
        dataIndex: '',
        width: '150px',
        className: title === 'Trésorerie' ? 'customerTableTreso' : 'customerTableCompta',
        render: (value, record) => renderCell(getMonthData(record, `${month}`, title))
      };
      arrayCoulnms.push(object);
    });
  };
  dataFilterArray();
  return [
    ...arrayCoulnms,
    {
      title: '',
      dataIndex: 'delete',
      width: '30px',
      className: 'ColoumnHeaderTitle',
      render: (value, record) => renderAction(record)
    }
  ];
}

function nestCategories(data, parentId = null) {
  return data?.reduce((r, e) => {
    if (parentId === e.parentId) {
      const children = nestCategories(data, e.id);
      const object = {
        ...e
      };
      if (children.length) {
        object.children = children;
      }
      r.push(object);
    }
    return r;
  }, []);
}

function getQueryStringParams(query) {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
}

function checkDragAndDropParent(id, object) {}

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export { getDefaultColumns, nestCategories, MONTHS, getQueryStringParams };
