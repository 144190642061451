import http from './http-common';

const create = (data) => {
  return http.post('/categories', data).then((response) => {
    return response.data;
  });
};
const get = (id) => {
  return http.get(`/categories/${id}`).then((response) => {
    return response.data;
  });
};

const listTreso = (startDate, endDate) => {
  const params = {};
  params.startDate = startDate;
  params.endDate = endDate;
  return http.get('/categories/all/treso', { params: params }).then((response) => {
    return response.data;
  });
};

const listCompta = (startDate, endDate) => {
  const params = {};
  params.startDate = startDate;
  params.endDate = endDate;
  return http.get('/categories/all/compta', { params: params }).then((response) => {
    return response.data;
  });
};
const listSoldeTrseo = (soldeFilter, startDate, endDate) => {
  const params = {};
  if (soldeFilter) {
    params.soldeFilter = soldeFilter;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  return http
    .get('/categories/all/treso', {
      params: params
    })
    .then((response) => {
      return response.data;
    });
};

const listSoldeCompta = (soldeFilter, startDate, endDate) => {
  const params = {};
  if (soldeFilter) {
    params.soldeFilter = soldeFilter;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  return http
    .get('/categories/all/compta', {
      params: params
    })
    .then((response) => {
      return response.data;
    });
};

const listCategoriePopup = (data) => {
  return http.get('/categories/all/cat', data).then((response) => {
    return response.data;
  });
};

const update = (id, data) => {
  return http.put(`/categories/${id}`, data).then((response) => {
    return response.data;
  });
};

const deleteCategorie = (categorieId, soldeFilter, startDate, endDate) => {
  const params = {};
  if (soldeFilter) {
    params.soldeFilter = soldeFilter;
  }
  params.startDate = startDate;
  params.endDate = endDate;
  return http
    .delete(`/categories/${categorieId}`, {
      params: params
    })
    .then((response) => {
      return response;
    });
};
export default {
  get,
  create,
  listTreso,
  update,
  deleteCategorie,
  listCategoriePopup,
  listSoldeTrseo,
  listSoldeCompta,
  listCompta
};
