import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.scss';
import Home from './pages/Home/Home';
function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Home />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
