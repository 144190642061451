import React, { useState, useEffect } from 'react';
import './Header.scss';
import { DatePicker, Popover, Button, Tooltip, Select, Modal } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import kwanto from '../../assets/image/KWANTO.svg';
import filter from '../../assets/icon/filtre.svg';
import person from '../../assets/icon/user.svg';
import modeTreso from '../../assets/icon/modeTreso.svg';
import modeCompta from '../../assets/icon/modeCompta.svg';
import prev from '../../assets/icon/prev.svg';
import engage from '../../assets/icon/engage.svg';
import pointe from '../../assets/icon/pointe.svg';
import simulation from '../../assets/icon/simulation.svg';
import reel from '../../assets/icon/reel.svg';
import add from '../../assets/icon/add.svg';

import InputSearch from '../InputSearch/InputSearch';
import PopupCustomer from '../PopupCustomer/PopupCustomer';
import { colorSoldeTotal, filterDateAction, listCategorie, listSolde, loadingToggleAction } from '../../redux/actions/categories';
import { filterSoldeByColor } from '../../helpers/filterSoldeByStatus';
import { deleteElement } from '../../redux/actions/element';
const Header = ({ sendDataElement }) => {
  const elements = useSelector((state) => state.categories.elements);
  const categories = useSelector((state) => state.categories.categories);
  let dateNow = new Date();
  const [active, setActive] = useState(false);
  const [modeActive, setModeActive] = useState(false);
  const [mode, setMode] = useState('treso');
  // const [modeComptaActive, setModeComptaActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [typeEcritureDefault, setTypeEcritureDefault] = useState('');
  const [valueStatut, setValueStatut] = useState();
  const [nameCategory, setNameCategory] = useState('');
  const [idElement, setIdElement] = useState('');
  const [dateElement, setDateElement] = useState('');
  const [dateActive, setDateActive] = useState(false);
  const [startDate, setstartDate] = useState(dateNow.getFullYear() + '-' + 1);
  const [endDate, setendDate] = useState(dateNow.getFullYear() + '-' + 12);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [months, setMonths] = useState([
    'January 2023',
    'February 2023',
    'March 2023',
    'April 2023',
    'May 2023',
    'June 2023',
    'July 2023',
    'August 2023',
    'September 2023',
    'October 2023',
    'November 2023',
    'December 2023'
  ]);
  const [size, setSize] = useState('large');

  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname == '/') {
      setMode('treso');
    } else if (location.pathname == '/table-compta') {
      setMode('compta');
    }
  }, []);

  const checkElement = async () => {
    await elements?.map(async (el) => {
      if (el.libelle.length === 0 || el?.montant.length == 0) {
        setDateElement(el.dateTreso);
        setIdElement(el.id);
        categories.map((cat) => {
          if (el.categorieId === cat.id) {
            setNameCategory(cat.name);
          }
        });
        await setIsModalOpen(true);
      } else {
        await setIsModalOpen(false);
      }
    });
  };

  const handleClickMode = async (params) => {
    await checkElement();
    const color = filterSoldeByColor('');
    dispatch(colorSoldeTotal(color));
    if (params === 'treso') {
      setMode(params);
      dispatch(listCategorie(params, startDate, endDate));
      dispatch(listSolde('treso', '', startDate, endDate));
      setModeActive(!modeActive);
    } else if (params === 'compta') {
      setMode(params);
      dispatch(listCategorie(params, startDate, endDate));
      dispatch(listSolde('compta', '', startDate, endDate));
      setModeActive(!modeActive);
    }
  };

  const handleClickActive = () => {
    setActive(!active);
  };

  const showModal = () => {
    checkElement();
    setIsModalVisible(true);
    setDateActive(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalVisible(false);
  };

  const handleCancel = async () => {
    if (mode === 'treso') {
      dispatch(deleteElement(idElement, 'Trésorerie'));
    } else if (mode === 'compta') {
      dispatch(deleteElement(idElement, 'Comptabilité'));
    }
    setIsModalOpen(false);
  };

  const content = () => {
    return (
      <div className="popup-ajouterEcriture">
        <Button
          className="simulation"
          value={'simulation'}
          onClick={(e) => {
            setTypeEcritureDefault('Simulation');
            setValueStatut(1);
            showModal();
          }}>
          DE SIMULATION
        </Button>

        <Button
          className="relle"
          value={'relle'}
          onClick={async (e) => {
            checkElement();
            setTypeEcritureDefault('Relle');
            setValueStatut(2);
            showModal();
          }}>
          RELLE
        </Button>
      </div>
    );
  };

  const handleChangeDate = (value, dateString) => {
    const startDate = moment(dateString[0], 'YYYY-MM');
    const endDate = moment(dateString[1], 'YYYY-MM');
    setstartDate(startDate.format('YYYY-MM'));
    setendDate(endDate.format('YYYY-MM'));
    const monthArray = [];
    let currentMonth = startDate;
    while (currentMonth <= endDate) {
      monthArray.push(currentMonth.format('MMMM YYYY'));
      currentMonth = currentMonth.add(1, 'month');
    }
    setMonths(monthArray);
    if (mode === 'treso') {
      dispatch(listSolde('treso', '', moment(dateString[0], 'YYYY-MM').format('YYYY-MM'), moment(dateString[1], 'YYYY-MM').format('YYYY-MM')));
    } else if (mode === 'compta') {
      dispatch(listSolde('compta', '', moment(dateString[0], 'YYYY-MM').format('YYYY-MM'), moment(dateString[1], 'YYYY-MM').format('YYYY-MM')));
    }
  };

  React.useEffect(() => {
    dispatch(filterDateAction(startDate, endDate, months));
  }, [startDate, endDate, months]);

  const title = <span className="titlePopover">Ajouter une écriture</span>;

  /*  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };
  const options = [
    { label: 'Deux mois', value: 2 },
    { label: 'Trois mois', value: 3 },
    { label: 'Quatre mois', value: 4 },
    { label: 'Six mois', value: 6 },
    { label: 'Anneé', value: 12 }
  ]; */

  return (
    <>
      <div className="header">
        <div className="header__left">
          <div className="header__left__logo">
            <Link to="/">
              <img src={kwanto} />
            </Link>
          </div>
          <div className="header__left__datePicker">
            <RangePicker
              className="rangePicker"
              picker="month"
              onChange={handleChangeDate}
              defaultValue={[dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')]}
            />
          </div>
          {/*    <div className="selectGroupCompact">
            <Select
              size={size}
              defaultValue="Regroupement par"
              onChange={handleChange}
              style={{
                width: 200
              }}
              options={options.map((item) => ({
                value: item.value,
                label: item.label
              }))}
              bordered
            />
          </div> */}
        </div>
        <div className="header__right">
          <div className="header__right__mode">
            <div className="header__right__mode__treso">
              <Tooltip placement="bottomLeft" title={'Mode trésorerie'}>
                <Link to="/" onClick={() => handleClickMode('treso')}>
                  <img src={modeTreso} />
                </Link>
              </Tooltip>
            </div>
            <div className="header__right__mode__compta">
              <Tooltip placement="bottomLeft" title={'Mode Comptabilité'}>
                <Link to="/table-compta" onClick={() => handleClickMode('compta')}>
                  <img src={modeCompta} />
                </Link>
              </Tooltip>
            </div>
          </div>
          <div className="header_right_add-element">
            <Popover
              overlayClassName="popover-add-element"
              overlayStyle={{ paddingTop: '18px', paddingLeft: '6px' }}
              placement="bottomLeft"
              title={title}
              content={content}>
              <img src={add} />
            </Popover>
            {isModalVisible && !isModalOpen && (
              <PopupCustomer
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                typeEcritureDefault={typeEcritureDefault}
                valueStatut={valueStatut}
                sendDataElement={sendDataElement}
                mode={mode}
                dateActive={dateActive}
                months={months}
              />
            )}
          </div>

          <div className={active ? 'header__right__filterActive' : null} onClick={handleClickActive}>
            <img src={filter} />
            {active && !modeActive && (
              <div className="header__right__filterActive__modeFilterActiveTreso">
                <img src={prev} />
                <img src={engage} />
                <img src={pointe} />
              </div>
            )}
            {active && modeActive && (
              <div className="header__right__filterActive__modeFilterActiveCompta">
                <img src={simulation} />
                <img src={reel} />
              </div>
            )}
          </div>
          <div className="header__right__InputSearch">
            <InputSearch />
          </div>
          <div className="header__right__person">
            <img src={person} />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          footer={[
            <div key="Submit" className="footer">
              <div key="keyConfirmation" className="btn_Confirmation">
                <Button key="annuler" onClick={() => handleCancel()} className="annuler">
                  Annuler
                </Button>
                <Button key="valider" className="valider" onClick={() => handleOk()}>
                  Valider
                </Button>
              </div>
            </div>
          ]}
          title="Terminez la création"
          open={isModalOpen}>
          <p>
            Il y a un élément dans la catégorie: <span>{nameCategory}</span> et dans la date: <span>{dateElement}</span> qui attend pour finir la création.
          </p>

          <p>Cliquez sur valider pour terminer la création ou cliquez sur Annuler pour supprimer l&apos;élément.</p>
        </Modal>
      )}
    </>
  );
};

export default Header;
