export const CREATE_CATEGORIE_SUCCESS = 'CREATE_CATEGORIE_SUCCESS';
export const CREATE_CATEGORIE_FAIL = 'CREATE_CATEGORIE_FAIL';

export const LIST_CATEGORIE_SUCCESS = 'LIST_CATEGORIE_SUCCESS';
export const LIST_CATEGORIE_FAIL = 'LIST_CATEGORIE_FAIL';
export const LIST_CATEGORIE_All = 'LIST_CATEGORIE_All';

export const DELETE_CATEGORIE_SUCCESS = 'DELETE_CATEGORIE_SUCCESS';
export const DELETE_CATEGORIE_FAIL = 'DELETE_CATEGORIE_FAIL';

export const GET_CATEGORIE_SUCCESS = 'GET_CATEGORIE_SUCCESS';
export const GET_CATEGORIE_FAIL = 'GET_CATEGORIE_FAIL';

export const LIST_CATEGORIE_SUCCESS_FINAL = 'LIST_CATEGORIE_SUCCESS_FINAL';
export const LIST_ELEMENTS_SUCCESS = 'LIST_ELEMENTS_SUCCESS';

export const UPDATE_CATEGORIE_SUCCESS = 'UPDATE_CATEGORIE_SUCCESS';
export const UPDATE_CATEGORIE_FAIL = 'UPDATE_CATEGORIE_FAIL';

export const EDITING_ACTION = 'EDITING_ACTION';
export const COLOR_SOLDE_ACTION = 'COLOR_SOLDE_ACTION';
export const FILTER_DATE_ACTION = 'FILTER_DATE_ACTION';

export const LOADING_TOGGLE_ACTION = 'LOADING_TOGGLE_ACTION';
