// This file was created to avoid a semantic HTML error
import * as React from 'react';
import { Input } from 'antd';
import { getQueryStringParams } from '../../helpers/helpers';
import ajoutSousBudget from '../../assets/icon/ajoutSousBudget.svg';
import { useSelector, useDispatch } from 'react-redux';
import { createCategorie, deleteCategorie, editingAction, listCategorie, updateCategorie } from '../../redux/actions/categories';

export default function Td({
  children,
  expanded,
  dataIndex,
  refresh,
  setRefresh,
  dataSource,
  setDataSource,
  expandedCategories,
  setExpandedCategories,
  ...props
}) {
  const editing = useSelector((state) => state.categories.editing);
  const dateFilter = useSelector((state) => state.categories.dateFilter);

  const dispatch = useDispatch();
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    inputRef?.current?.focus();
  });

  const toggleEdit = (e) => {
    dispatch(editingAction(true, e.target.id));
  };

  const save = async (e) => {
    try {
      if (props.record.id === undefined) {
        await dispatch(createCategorie({ name: e.target.value, levelId: 1 }));
        await dispatch(listCategorie('treso', dateFilter.startDate, dateFilter.endDate));
      } else {
        if (e.target.value.length > 0) {
          dispatch(updateCategorie(props.record.id, { name: e.target.value }));
        } else {
          dispatch(deleteCategorie(props.record.id));
        }
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleAddSUb = async () => {
    setExpandedCategories(() => {
      const index = expandedCategories.findIndex((ec) => ec === props.record.key);
      if (index > -1 && !expanded) return expandedCategories.filter((ec) => ec !== props.record.key);
      else return [...expandedCategories, props.record.key];
    });
    const newChild = {
      name: '',
      parentId: props.record.key,
      levelId: props.record.levelId + 1
    };
    setDataSource([...dataSource]);
    dispatch(createCategorie(newChild));
    dispatch(editingAction(true));
  };

  return (
    <td
      className={
        dataIndex && dataIndex === 'name'
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last cattegory'
          : dataIndex && dataIndex === 'delete'
          ? 'table-td ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-last action'
          : 'table-td '
      }>
      {(dataIndex === 'name' && !props.record?.id && editing) || (props.record?.name?.length == 0 && dataIndex === 'name' && editing) ? (
        <>
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </>
      ) : (
        <div
          style={
            dataIndex && dataIndex === 'name'
              ? {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: '10px'
                }
              : {}
          }
          onDoubleClick={(e) => toggleEdit(e)}>
          {children?.map((c) => {
            return c ? (
              <div key={getQueryStringParams(c?.props.dragPrefix).category || new Date().toString()}>{React.cloneElement(c, { expanded })}</div>
            ) : null;
          })}
          {dataIndex === 'name' && props.record.levelId !== 5 && (
            <div>
              <img src={ajoutSousBudget} className="ajoutBSousudget" onClick={() => handleAddSUb()} />
            </div>
          )}
        </div>
      )}
    </td>
  );
}
