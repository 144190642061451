import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CustomTable from '../../components/CustomTable/CustomTable';
import TableCustomer from '../../components/Table/TableCustomer';
import Categories from '../Categories/Categories';
import Dashboard from '../Dashboard/Dashboard';
import Notifications from '../Notifications/Notifications';
import Settings from '../Settings/Settings';

const MainPage = () => {
  return (
    <React.Fragment>
      <Routes>
        {/* <Route path="/" element={<TableCustomer title={'Trésorerie'} classStyle={'customerTableTreso'} />} /> */}
        <Route path="/" element={<CustomTable title={'Trésorerie'} classStyle={'customerTableTreso'} />} />
        <Route path="/table-compta" element={<CustomTable title={'Comptabilité'} classStyle={'customerTableCompta'} />} />
        <Route path="/Categories" element={<Categories />} />
        <Route path="/chart" element={<Dashboard />} />
        <Route path="/notif" element={<Notifications />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </React.Fragment>
  );
};

export default MainPage;
